import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"d-block"},[_c(VRow,{staticClass:"mb-12"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"card-hover fill-height",attrs:{"outlined":""}},[_c(VAppBar,{attrs:{"elevation":"0","flat":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t("storage.titles.producers")))]),_c(VSpacer),_c(VImg,{staticClass:"mx-4",attrs:{"src":require('@/assets/icons/firstline.svg'),"contain":"","height":"25px","max-height":"25px","max-width":"25px","width":"25px"}})],1),_c(VCardText,[(_vm.action === 'show')?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataIterator,{staticClass:"elevation-0",attrs:{"items":_vm.producerDetails,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":_vm.$t('general.ui.not_data_found'),"no-results-text":_vm.$t('general.ui.not_search_found'),"loading-text":_vm.$t('general.notification.loadData'),"checkbox-color":"secondary","footer-props":{
                  ..._vm.rowsPerPageItemsOptions,
                  itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
                  pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}'
                }},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c(VRow,_vm._l((items),function(producer){return _c(VCol,{key:producer.id,attrs:{"cols":"3"}},[_c(VCard,{attrs:{"elevation":"0","flat":"","outlined":""}},[_c(VCardText,[_vm._l((producer),function(value,key){return (!['id', 'observations', 'storageDevices', 'companyId', 'isDeleted', 'isActive'].includes(key))?_c('div',{key:key},[_c('strong',[_vm._v(_vm._s(_vm.$t(`producer.fields.${key}`)))]),_vm._v(": "+_vm._s(value || '-')+" ")]):_vm._e()}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t(`producer.fields.isActive`)))]),_vm._v(": "),(producer.isActive)?_c(VChip,{attrs:{"color":"secondary","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('storage.fields.isActive.yes'))+" ")]):_c(VChip,{attrs:{"color":"error","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('storage.fields.isActive.no'))+" ")])],1)],2)],1)],1)}),1)]}}],null,false,1196240342)})],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }