import { render, staticRenderFns } from "./SUCListComponent.vue?vue&type=template&id=1609ecb6&scoped=true&"
import script from "./SUCListComponent.vue?vue&type=script&lang=js&"
export * from "./SUCListComponent.vue?vue&type=script&lang=js&"
import style0 from "./SUCListComponent.vue?vue&type=style&index=0&id=1609ecb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1609ecb6",
  null
  
)

export default component.exports