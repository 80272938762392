<template>
  <v-list dense>
    <v-list dense v-if="items.length > 0">
      <div class="d-block" v-for="(item, index) in origins" :key="index">
        <div class="d-flex justify-start align-center align-content-center">
          <v-icon style="font-size: 16px"
                  :style="!verifyOrigin(item) ? 'color: green' : 'color: red'"
          >
            {{ !verifyOrigin(item) ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
          </v-icon>
          <p class="ma-0 pa-0 mx-2">{{ item }}</p>
        </div>
      </div>
    </v-list>
    <div v-else class="d-flex justify-start align-center align-content-center">
      <p class="ma-0 pa-0 mx-2">{{ $t('storage.integrations.brainag.withoutInfractions') }}</p>
    </div>
  </v-list>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'ComplianceListComponent',
    props: {
      items: {
        type: Array,
        required: true
      }
    },

    computed: {
      verifyOrigin() {
        return (item) => _.find(this.items, (i) => {
          return item === i.origem
        })
      }
    },

    data:() => ({
      origins: ["EMBARGO_LDI", "EMBARGO_SEMA", "EMBARGO_ICMBIO", "DESEMBARGO_SEMA", "PRODES_CERRADO", "PRODES_AMAZONIA", "PROPRIEDADES", "ASSENTAMENTOS", "UNIDADES_CONSERVACAO", "TERRAS_INDIGENAS", "QUILOMBOS"]
    })
  }
</script>

<style scoped>

</style>