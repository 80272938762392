<template>
  <v-list dense class="car-list fill-height" tile style="border: 1px solid #e5e5e5; border-radius: .3em !important;">
    <!-- Mostrar mensaje cuando no haya carros -->
    <v-list-item v-if="cars.length === 0">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium grey--text">
          {{ $t("general.ui.not_data_found") }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- Renderizar carros cuando haya data -->
    <span v-else>
      <v-list-item v-for="(car, index) in cars" :key="index">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium black--text">
          <div class="car-chip pa-4" @mouseover="hoverIndex = index"
                  @mouseleave="hoverIndex = null">
            {{ car.toUpperCase() }}
            <v-icon
              small
              @click="copyToClipboard(car)"
              class="ml-2 copy-icon"
            >
                  mdi-content-copy
                </v-icon>

          </div>
          <v-divider />
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action v-if="showActions">
        <div class="d-flex justify-space-between">
          <v-btn small text icon>
            <v-icon @click="removeCar(index)">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
    </span>
  </v-list>
</template>

<script>
  export default {
    props: {
      cars: {
        type: Array,
        required: true,
      },
      showActions: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        hoverIndex: null,
      }
    },
    methods: {
      removeCar(index) {
        this.$emit("remove-car", index)
      },
      copyToClipboard(car) {
        const textArea = document.createElement("textarea")
        textArea.value = car
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand("copy")
        document.body.removeChild(textArea)
      },
    },
  }
</script>

<style scoped>
  .car-list {
    max-height: 360px;
    overflow-y: auto;
  }

  .car-chip {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    white-space: nowrap;
    transition: width 0.3s ease;
  }

  .copy-icon {
    cursor: pointer;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .car-chip:hover .copy-icon {
    opacity: 1;
    transform: translateX(0);
  }
</style>
