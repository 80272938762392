<template>
  <v-layout class="d-block" v-if="!showDocumentDetail">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card outlined class="card-hover fill-height">
          <v-app-bar elevation="0" flat>
            <h2>{{ $t("menu.document_type") }}</h2>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchText"
              :label="$t('general.fields.startTyping')"
              hide-details
              outlined
              dense
              color="secondary"
              append-icon="mdi-magnify"
              class="mx-4 search__input"
              background-color="white"
            />
            <v-slider :min="2"
                      :max="6"
                      step="2"
                      v-model="size"
                      color="black"
                      track-fill-color="black"
                      thumb-color="black"
                      hide-details
                      track-color="grey"
                      dense
                      class="slider__folder-size d-flex justify-end"
            >
              <template #prepend>
                <v-btn :disabled="size === 2" text small @click="size -= 2">
                  <v-icon>mdi-magnify-minus-outline</v-icon>
                </v-btn>
              </template>
              <template #append>
                <v-btn :disabled="size === 6" text small @click="size += 2">
                  <v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
              </template>
            </v-slider>
            <v-img
              class="fill-icon__color mx-4"
              :src="require('@/assets/icons/folder-connection.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
          <v-card-text class="ma-0 pa-8">
            <v-row class="d-flex justify-start" v-if="(paginatedDocumentTypes && paginatedDocumentTypes.length > 0)">
              <v-col :cols="size" v-for="(type, index) in paginatedDocumentTypes" :key="type.id">
                <v-card outlined style="cursor: pointer"
                        ripple
                        @click="openFolder(type)">
                  <v-card-text>
                    <v-icon>mdi-folder</v-icon>
                    <v-tooltip color="black" top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ translate(type.language_key) | truncate(5*size) }}
                        </span>
                      </template>
                      <span>
                        {{ translate(type.language_key) }}
                      </span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-content-center align-center" v-else>
              {{ $t('general.ui.not_data_found') }}
            </v-row>
            <FooterPaginationComponent
              :values="filteredDocumentTypes"
              :amount="40"
              :valid="true"
              :page="paginationOptions.page"
              :per-page="paginationOptions.itemsPerPage"
              :items-per-page="paginationOptions.itemsPerPageOptions"
              @update:items="updatePaginationOptions"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
  <v-layout class="d-block" v-else>
    <router-view @document:details="showDocumentDetail = $event" ></router-view>
  </v-layout>
</template>

<script>
  import _ from "lodash"
  import i18n from "@/plugins/i18n";
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService";
  import FooterPaginationComponent from "@/module/records/components/commons/FooterPaginationComponent.vue";

  export default {
    name: "TabDocumentsLocation",
    components: {FooterPaginationComponent},
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      showDocumentDetail: false,
      ready: false,
      loading: false,
      clone: {},
      size: 2,
      searchText: '',
      paginationOptions: {
        page: 1,
        itemsPerPage: 40,
        itemsPerPageOptions: [20, 40, 60, 80],
        showFirstLastPage: true,
      },
    }),

    watch: {
      '$route'(to, from) {
        if (from.name === 'document-type-detail' && to.name === 'storage-management') {
          this.showDocumentDetail = false;
          this.documents = [];
        }
      },
    },

    async created() {
      this.clone = _.cloneDeep(this.item)

      await this.fetchDocumentTypes([{
        storageDeviceId: this.clone.storageDeviceId
      }, this.$toast])
    },

    computed: {
      ...mapGetters({
        documentTypes: 'storage/GET_DOCUMENT_TYPES',
      }),
      documents: {
        get() {
          return this.$store.getters['documents/filterDocuments']
        },
        set(val) {
          this.$store.commit('documents/SET_FILTERS_DOCUMENTS_LIST', val)
        },
      },
      filteredDocumentTypes() {
        return this.documentTypes.filter(type =>
          this.translate(type.language_key).toLowerCase().includes(this.searchText.toLowerCase())
        );
      },
      paginatedDocumentTypes() {
        const start = (this.paginationOptions.page - 1) * this.paginationOptions.itemsPerPage;
        const end = start + this.paginationOptions.itemsPerPage;
        return this.filteredDocumentTypes.slice(start, end);
      }
    },

    methods: {
      ...mapActions({
        fetchDocumentTypes: 'storage/fetchDocumentTypes'
      }),
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      updatePaginationOptions(options) {
        this.paginationOptions = { ...this.paginationOptions, ...options };
      },
      openFolder(type) {
        this.showDocumentDetail = true;
        this.$router.push({
          name: 'document-type-detail',
          params: {
            name: _.kebabCase(this.translate(type.language_key).toLowerCase()),
            documentType: type,
            storage: this.clone,
            action: 'show'
          }
        });
      },
    },
  }
</script>

<style scoped lang="scss">
:deep(.v-input.slider__folder-size) {
  max-width: 20% !important;
}

:deep(.search__input) {
  max-width: 20% !important;
}
</style>
