import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"d-block"},[_c(VRow,{staticClass:"mb-12"},[_c(VCol,{attrs:{"cols":"6"}},[(_vm.integrations.WALLET)?_c(VCard,{staticClass:"card-hover fill-height",attrs:{"outlined":""}},[_c(VAppBar,{attrs:{"elevation":"0","flat":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t("storage.titles.blockchainConfig")))]),_c(VSpacer),_c(VImg,{staticClass:"mx-4",attrs:{"src":require('@/assets/icons/chainlink.svg'),"contain":"","height":"25px","max-height":"25px","width":"25px","max-width":"25px"}})],1),_c(VCardText,{staticClass:"ma-0 pa-8"},[_c(VSwitch,{staticClass:"ma-0 pa-0 d-flex justify-space-between align-center align-content-center",attrs:{"disabled":_vm.action === 'show',"color":"secondary","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VLabel,{ref:"label"},[_vm._v(_vm._s(_vm.$t("storage.fields.isVirtual")))])]},proxy:true}],null,false,2402278055),model:{value:(_vm.item.isVirtual),callback:function ($$v) {_vm.$set(_vm.item, "isVirtual", $$v)},expression:"item.isVirtual"}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }