<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-overlay
              v-show="loading"
              color="white"
              opacity="0.9"
              style="z-index: 2"
            >
              <v-progress-circular
                style="left: 150px"
                class="mx-auto"
                size="50"
                indeterminate
                color="secondary"
              />
            </v-overlay>
            <v-tabs
              color="secondary"
              v-model="tabs"
              hide-slider
              dark
              height="60"
            >
              <v-tab
                v-show="action === 'show'"
                class="ma-0 py-0 px-4"
                style="color: #006855"
              >
                {{ $t("locations.titles.preview") }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
                style="color: #006855"
              >
                {{ $t("locations.titles.main") }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
                style="color: #006855"
              >
                {{ $t("locations.titles.map") }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
                style="color: #006855"
              >
                {{ $t("locations.titles.media") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                style="color: #006855"
                v-show="integrations.BRAINAG && (checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read','Create'] }])))"
              >
                {{ $t("locations.titles.checkSocioambiental") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                style="color: #006855"
              >
                {{ $t("badge.titles.name") }}
              </v-tab>

              <v-tab
                v-show="action === 'show'"
                class="ma-0 pa-0 px-4"
                style="color: #006855"
              >
                {{ $t("storage.titles.producers") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                style="color: #006855"
              >
                {{ $t("storage.titles.extraData") }}
              </v-tab>

              <v-tab
                v-show="action === 'show'"
                class="ma-0 pa-0 px-4"
                style="color: #006855"
              >
                {{ $t("reports.titles.documents") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                style="color: #006855"
                v-show="integrations.WALLET"
              >
                {{ $t("company.titles.configurations") }}
              </v-tab>

              <v-tabs-items v-model="tabs">
                <v-tab-item :value="0">
                  <v-card flat>
                    <v-card-text>
                      <TabPreviewLocation :item="editedItem" :loading="!loading" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="1">
                  <v-card flat>
                    <v-card-text>
                      <TabMainLocation
                        v-if="action === 'new' || editedItem.id"
                        @update:validation="forms.main = $event"
                        :action="action"
                        :item="editedItem"
                        :is-ready="isReady"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="2">
                  <v-card flat>
                    <v-card-text>
                      <TabMapLocation
                        @update:validation="forms.map = $event"
                        @update:turf="turfDefaultUnit = $event"
                        @update:model="updateModel($event)"
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="3">
                  <v-card flat>
                    <v-card-text>
                      <TabMediaLocation
                        :action="action"
                        :item="editedItem"
                        @update:item="$set(editedItem, $event)"
                        @update:images="updateImages($event)"
                        @update:logo="updateLogo($event)"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  :value="4"
                  v-if="integrations.BRAINAG && (checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read','Create'] }])))"
                >
                  <v-card flat>
                    <v-card-text>
                      <TabCheckLocation :action="action" :item="editedItem" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="5">
                  <v-card flat>
                    <v-card-text>
                      <TabBadgeLocation
                        :action="action"
                        :item="editedItem"
                        @update:item="$set(editedItem, $event)"
                        @update:badgesFiles="updateBadgesFiles($event)"
                        @update:badges="updateBadges($event)"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="6">
                  <v-card flat>
                    <v-card-text>
                      <TabProducerLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="7">
                  <v-card flat>
                    <v-card-text>
                      <TabExtraDataLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="8" v-show="action === 'show'">
                  <v-card flat>
                    <v-card-text>
                      <TabDocumentsLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item :value="9" v-show="integrations.WALLET">
                  <v-card flat>
                    <v-card-text>
                      <TabConfigurationLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar
      bottom
      style="position: fixed"
      max-width="100%"
      width="-webkit-fill-available"
      class="px-6"
    >
      <v-switch
        class="mx-2"
        v-model="editedItem.active"
        color="secondary"
        hide-details
        :disabled="action === 'show' || isVersion"
      >
        <template v-slot:prepend>
          <v-label ref="label">{{ $t("locations.fields.active") }} </v-label>
        </template>
      </v-switch>
      <v-switch
        class="mx-2"
        v-model="editedItem.productive"
        color="secondary"
        hide-details
        :disabled="action === 'show' || isVersion"
      >
        <template v-slot:prepend>
          <v-label ref="label" class="full-width"
            >{{ $t("storage.fields.prd") }}
          </v-label>
        </template>
      </v-switch>
      <v-spacer />
      <span v-if="['new', 'edit'].includes(action)">
        <v-btn text color="grey" @click="back()">{{
          $t("general.buttons.cancel")
        }}</v-btn>
        <v-btn text color="secondary" @click="save()" :disabled="!valid">{{
          $t("general.buttons.confirm")
        }}</v-btn>
      </span>
      <span v-else>
        <v-btn text color="grey" @click="back()">{{
          $t("general.buttons.back")
        }}</v-btn>
      </span>
    </v-toolbar>
  </div>
</template>

<script>
  import _ from "lodash"
  import Pbf from "pbf"
  import geobuf from "geobuf"
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService"
  import TabPreviewLocation from "@/module/configuration/storage/components/tabs/items/TabPreviewLocation.vue"
  import TabMainLocation from "@/module/configuration/storage/components/tabs/items/TabMainLocation.vue"
  import TabMapLocation from "@/module/configuration/storage/components/tabs/items/TabMapLocation.vue"
  import TabMediaLocation from "@/module/configuration/storage/components/tabs/items/TabMediaLocation.vue"
  import TabBadgeLocation from "@/module/configuration/storage/components/tabs/items/TabBadgeLocation.vue"
  import TabCheckLocation from "@/module/configuration/storage/components/tabs/items/TabCheckLocation.vue"
  import TabConfigurationLocation from "@/module/configuration/storage/components/tabs/items/TabConfigurationLocation.vue"
  import TabDocumentsLocation from "@/module/configuration/storage/components/tabs/items/TabDocumentsLocation.vue"
  import TabExtraDataLocation from "@/module/configuration/storage/components/tabs/items/TabExtraDataLocation.vue"
  import MapService from "@/services/MapService"
  import LanguajeService from "@/services/LanguajeService"
  import PermissionService from "@/services/PermisionService";
  import TabProducerLocation from "@/module/configuration/storage/components/tabs/items/TabProducerLocation.vue";
  import { getByProperty } from "@/utils"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {
      TabProducerLocation,
      TabPreviewLocation,
      TabMainLocation,
      TabMapLocation,
      TabMediaLocation,
      TabBadgeLocation,
      TabCheckLocation,
      TabConfigurationLocation,
      TabDocumentsLocation,
      TabExtraDataLocation,
    },
    props: {
      uuid: String,
      action: String,
    },
    data: () => ({
      tabs: null,
      loading: true,
      turfDefaultUnit: null,
      editedIndex: null,
      isVersion: false,
      forms: {
        main: false,
        map: false,
      },
      editedItem: {
        id: null,
        country: null,
        control_label: "",
        company_id: null,
        storage_device_type_id: null,
        storageDeviceType: null,
        primaryLocation: null,
        location: null,
        logo: null,
        latitude: "",
        longitude: "",
        productive: true,
        locationOwner: null,
        mediumHeight: 0,
        mediumTemperature: 0,
        area: 0,
        gln: "",
        measurementUnit: null,
        measurement_unit_id: null,
        active: true,
        comercialSite: "",
        description: "",
        description_text: "",
        personInChargeIds: [],
        updateDocuments: false,
        updateBadges: false,
        updateLogo: false,
        isVirtual: false,
        extraData: [],
        producers: [],
        storageDeviceId: null,
        carData: [],
        socialMedia: {
          video: "",
          facebook: "",
          instagram: "",
          twitter: "",
        },
        images: [],
        badgesFiles: [],
        badges: [],
        updateVersion: false,
        classificationType: null,
      },
      isReady: false,
      integrations: {
        BRAINAG: null,
        WALLET: null,
      },
    }),

    async created() {
      this.tabs = this.action === "show" ? 0 : 1

      if (this.action !== "show") await this.fetchAllProducer();

      this.isVersion = JSON.parse(localStorage.getItem("storage:isVersion")) === 1 ;

      this.integrations.WALLET = _.find(PROFILE.integrationsCompany, {
        integrationCompanyType: "WALLET",
      })

      this.integrations.BRAINAG = _.find(PROFILE.integrationsCompany, {
        integrationCompanyType: "BRAINAG",
      })

      await this.fetchStorageTypesActiveByCompany([this.$toast])

      await this.fetchListCountry()

      this.$set(this.editedItem, "personsInCharge", [PROFILE]);
      this.$set(this.editedItem, "personInChargeIds", [PROFILE.id]);

      if (this.action !== "new") {
        if (!this.uuid) await this.$router.push({ name: "storage" })

        this.editedIndex = localStorage.getItem("storage_editOrSave")

        this.editedItem = await this.fetchStorageById([this.uuid, this.$toast])

        this.editedItem.updateVersion = this.isVersion;

        // await this.fetchPrimaryLocations([
        //   {
        //     storageDeviceTypeIds: [this.editedItem.storageDeviceType.id],
        //     isActive: true,
        //   },
        //   this.$toast,
        // ]).finally(() => {
        //   console.log(this.locations)
        // })

        if (this.editedItem.images && this.editedItem.images.length > 0) {
          this.$set(
            this.editedItem,
            "images",
            _.map(this.editedItem.images, d => {
              return { url: d.s3_url, ...d }
            })
          )
        } else this.editedItem.images = []

        if (this.editedItem.badges && this.editedItem.badges.length > 0) {
          this.$set(
            this.editedItem,
            "badgesFiles",
            _.map(this.editedItem.badges, d => {
              return { initialized: true, url: d.s3_url, ...d }
            })
          )
          this.$set(
            this.editedItem,
            "badges",
            _.map(this.editedItem.badges, d => {
              return { initialized: true, description_text: this.translate(d.description), ...d }
            })
          )
        } else {
          this.editedItem.badgesFiles = []
          this.editedItem.badges = []
        }

        if (this.editedItem.logo && this.editedItem.logo.s3_url) {
          this.$set(this.editedItem, "logo", {
            url: this.editedItem.logo.s3_url,
            ...this.editedItem.logo,
          })
        } else {
          this.$set(this.editedItem, "logo", null)
        }

        this.editedItem.storageDeviceType = this.editedItem.storageDeviceType
          ? this.storageDeviceTypes.find(
              x =>
                x.storage_device_type_id ===
                this.editedItem.storageDeviceType.id
            )
          : null

        if (this.action === "edit" && this.editedItem.description) {
          this.editedItem.description_text = this.translate(
            this.editedItem.description
          )
        }

        this.turfDefaultUnit = _.find(this.allUnits, { code: "ha" })
      }

      this.isReady = true
      this.loading = false
    },

    computed: {
      ...mapGetters({
        storageDeviceTypes: "storage_type/storageDeviceTypesActiveByCompany",
        allUnits: "units/allObjectUnits",
        languageStructure: "general/languageStructure",
        locations: "locations/locationsList",
      }),
      itemColor: {
        get() {
          return this.$store.getters["general/color"]
        },
        set(val) {
          this.$store.commit("general/SET_COLOR", val)
        },
      },
      valid() {
        return this.forms.main
      },
    },

    mounted() {
      if (this.action === "edit") {
        this.forms.main = true
        this.forms.map = true
      }
    },

    methods: {
      ...mapActions({
        fetchStorageById: "storage/fetchStorageById",
        saveStorage: "storage/saveStorage",
        fetchStorageTypesActiveByCompany:
          "storage_type/fetchStorageTypesActiveByCompany",
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchListCountry: "general/fetchListCountry",
        fetchListStorage: "authority/getAuthoritiesStorageDevices",
        fetchAllProducer: "producer/fetchAllProducer",
        fetchAreaRange: "ui/fetchSecondaryRange"
      }),
      // Check permission
      check(permissions) {
        return PermissionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermissionService.checkAdmin()
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      updateModel(e) {
        this.$set(this, "editedItem", e)
      },
      getFileExtension(url, item) {
        if (url.includes("s3-accelerate.amazonaws.com")) {
          return item.file_extension
        } else {
          let lastSegment = url.split("/").pop()
          let matches = lastSegment.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
          if (matches && matches.length > 1) {
            return matches[1]
          } else {
            return "jpg"
          }
        }
      },
      scapeUrl(url) {
        let partes = url.split("/")
        let basePath = partes.slice(0, 4).join("/") // 'https://agtracestorage.s3-accelerate.amazonaws.com/abrapa'
        let resourcePath = partes.slice(4).join("/") // '$2a$10$Mf/mc2aX77/W.We3fH.T0OTLhmOZ7WzH.5Ur74/hsWtS3q5CZoEoG'
        resourcePath = encodeURIComponent(resourcePath)
        return `${basePath}/${resourcePath}`
      },
      mapDataReload(){
        this.fetchListStorage([{
          userId: PROFILE.id,
          companyId: PROFILE.company_id,
          isActive: true
        }, this.$toast])
      },
      async save() {
        this.loading = true

        let item = Object.assign({}, this.editedItem)
        let geobufData = null
        if (
          this.IsJsonString(this.editedItem.location) &&
          this.editedItem.location &&
          this.editedItem.location !== "null"
        ) {
          // Compress Geojson with Geobuf
          this.editedItem.location = JSON.parse(this.editedItem.location)
          this.editedItem.location.features = _.map(
            this.editedItem.location.features,
            feature => {
              feature.id = null
              return feature
            }
          )
          geobufData = geobuf.encode(this.editedItem.location, new Pbf())
          item.locationFormat = "GEOBUF"
          item.location = null
        } else {
          let locationClone = null
          if (this.editedItem.locationFormat === "GEOBUF") {
            locationClone = await MapService.singleLocationFormat(
              this.editedItem
            )
            this.$set(this, "editedItem", locationClone)
            geobufData = geobuf.encode(
              JSON.parse(this.editedItem.location),
              new Pbf()
            )
            item.location = null
          }
        }

        item.storageDeviceType = {
          id: item.storageDeviceType.storage_device_type_id,
        }
        item.measurementUnit = this.turfDefaultUnit

        if (item.id === null) item.company = { id: PROFILE.company_id }

        item.description = LanguageService.setKey3(
          item.description_text,
          item.description,
          !item.id ? this.languageStructure : null
        )

        item.color = this.itemColor

        item.personInChargeIds = _.map(item.personsInCharge, p => p.id)
        item.producers = _.map(item.producers, p => p.id)

        const document = {
          documentAuthority: { id: 1 },
          documentType: { id: 1 },
          documentDescription: { id: 1 },
          company: { id: item.company.id },
        }

        let logo = null
        if (item.logo && item.logo.url && item.updateLogo) {
          let url = item.logo.url

          if (!url.startsWith("blob:")) {
            url = this.scapeUrl(url)
          }

          await fetch(url)
            .then(r => r.blob())
            .then(blobFile => {
              let extension = this.getFileExtension(url, item.logo)
              logo = new File([blobFile], "logo", {
                type: `image/${extension}`,
              })
            })
        }

        if (item.images && item.images.length > 0 && item.updateDocuments) {
          for (let i = 0; i < item.images.length; i++) {
            let url = item.images[i].url

            if (!url.startsWith("blob:")) {
              url = this.scapeUrl(url)
            }

            try {
              let response = await fetch(url)
              let data = await response.blob()
              let extension = this.getFileExtension(url, item.images[i])
              let file = new File([data], `image_${i}.${extension}`, {
                type: `image/${extension}`,
              })
              item.images[i] = file
            } catch (error) {
              console.error("Error al obtener la imagen:", error)
            }
          }
        }

        if (
          item.badgesFiles &&
          item.badgesFiles.length > 0 &&
          item.updateBadges
        ) {
          for (let i = 0; i < item.badgesFiles.length; i++) {
            delete item.badges[i]['classification'];

            let url = item.badgesFiles[i].url

            if (!url.startsWith("blob:")) {
              url = this.scapeUrl(url)
            }

            try {
              let response = await fetch(url)
              let data = await response.blob()
              let extension = this.getFileExtension(url, item.badgesFiles[i])
              let file = new File([data], `image_${i}.${extension}`, {
                type: `image/${extension}`,
              })
              item.badgesFiles[i] = file
              item.badges[i].file_associate_code = file.name
              item.badges[i].size = file.size
              item.badges[i].file_extension = extension
              item.badges[i].description = LanguajeService.setKey3(
                item.badges[i].description_text,
                item.badges[i].description,
                !item.badges[i].id ? this.languageStructure : null
              )
            } catch (error) {
              console.error("Error getting the badge:", error)
            }
          }
        }

        // normalize address (send only names)
        item = {
          ...item,
          country: getByProperty(item.country, 'name'),
          state: getByProperty(item.state, 'name'),
          city: getByProperty(item.city, 'name')
        }

        console.log(item)

        await this.saveStorage([
          item,
          this.editedIndex,
          document,
          logo,
          document,
          item.images,
          item.badgesFiles,
          geobufData,
          this.$toast,
        ])
          .then(async (res) => {
            if(res)
              await this.fetchAreaRange().finally(() => {
                this.back();
              });
          })
          .finally(() => {
            this.mapDataReload()
            this.loading = false
          })
      },
      IsJsonString(json) {
        try {
          JSON.parse(json)
        } catch (e) {
          return false
        }
        return true
      },
      updateImages(e) {
        this.$set(this.editedItem, "images", e)
      },
      updateBadgesFiles(e) {
        this.$set(this.editedItem, "badgesFiles", e)
      },
      updateBadges(e) {
        this.$set(this.editedItem, "badges", e)
      },
      updateLogo(e) {
        this.$set(this.editedItem, "logo", e)
      },
      back() {
        this.clear();
        this.$router.push({ name: "storage" })
      },
      clear() {
        localStorage.removeItem("storageItem")
        localStorage.removeItem("storage_editOrSave")
        localStorage.removeItem("storage:isVersion")
      }
    },

    destroyed() {
      this.integrations = {
        BRAINAG: null,
        WALLET: null,
      }

      this.clear();
    },
  }
</script>

<style scoped>
  :deep(.theme--dark.v-tabs > .v-tabs-bar) {
    background-color: transparent;
  }

  :deep(.v-slide-group__wrapper) {
    display: grid;
    justify-content: start;
    background-color: #f1f1f1;
    border-radius: 0.3em;
    margin: 0 15px;
  }

  :deep(.theme--light.v-tabs:not(.v-tabs--vertical) .v-tab) {
    margin: 0 !important;
    padding: 0 10px !important;
    font-size: 14px;
  }

  .v-tab--active {
    color: white !important;
    background-color: #03ac8c !important;
  }
</style>