<template>
  <v-list dense>
    <div v-if="item.result.length > 0" class="d-flex justify-start align-center align-content-center">
      <v-icon style="color: red; font-size: 16px">
        mdi-check-circle-outline
      </v-icon>
      <p class="ma-0 pa-0 mx-2"><strong>CNAE:</strong> {{ item.result[0].cnae }} | {{ item.result[0].ano }}</p>
    </div>
    <div v-else class="d-flex justify-start align-center align-content-center">
      <v-icon style="color: green; font-size: 16px">
        mdi-alert-circle-outline
      </v-icon>
      <p class="ma-0 pa-0 mx-2">{{ $t('storage.integrations.brainag.withoutInfractions') }}</p>
    </div>
  </v-list>
</template>

<script>
  export default {
    name: 'SlaveryListComponent',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>