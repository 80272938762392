<template>
  <v-list dense v-if="items.length > 0">
    <div class="d-block mt-2 containerSUC" v-for="(item, key) in items">
      <div class="d-flex justify-space-between align-items-center" style="display: flex;">
        <p class="ma-0 pa-0 textSUC">
          <strong>{{ fetchTitle(item.origem) }}:</strong>
        </p>
        <p class="ma-0 pa-0 textSUC">
          {{ item.area.toFixed(2) }} Ha
        </p>
      </div>
    </div>
  </v-list>
  <div v-else class="d-flex justify-start align-center align-content-center">
    <p class="ma-0 pa-0 mx-2">{{ $t('storage.integrations.brainag.withoutUseEarth') }}</p>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'SUCListComponent',
    props: {
      items: {
        type: Array,
        required: true
      }
    },

    computed: {
      title() {
        return (item) => _.find(this.origins, (i) => {
          if (item.origem === i.key)
            return i.name
          else
            return item.origem
        })
      }
    },

    data:() => ({
      origins: [{
        key: "SOYBEAN",
        name: "Soja PASTURE - Pasto"
      },{
        key: "SUGAR_CANE",
        name: "Cana"
      },{
        key: "FOREST_FORMATION",
        name: "Formação Florestal"
      },{
        key: "GRASSLAND_FORMATION",
        name: "Formação Campestre"
      },{
        key: "SAVANNA_FORMATION",
        name: "Formação Savânica"
      },{
        key: "CONSOLIDATED_AREA",
        name: "Área Consolidada"
      },{
        key: "LEGAL_RESERVE",
        name: "Reserva Legal"
      },{
        key: "NATIVE_VEGETATION",
        name: "Vegetação Nativa"
      }]
    }),

    methods: {
      fetchTitle(origem) {
        const result = _.find(this.origins, { key: origem })
        if (result)
          return result.name;
        else
          return origem;
      }
    }
  }
</script>

<style scoped>
  .containerSUC {
    width: 350px;
  }
  .textSUC{
    font-size: 14px;
  }
</style>