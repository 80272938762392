<template>
  <v-card class="px-6 py-2" color="transparent" elevation="0" outlined>
    <v-card-title class="my-0 py-0">
      <strong>CPF</strong>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p
            class="ma-0 ma-0 mx-4 black--text"
            style="font-size: 18px"
            v-on="on"
          >
            <strong
              >{{ items[0].documentType["document"] | truncate(20) }}
            </strong>
          </p>
        </template>
        <span>{{ items[0].documentType["document"] }}</span>
      </v-tooltip>
    </v-card-title>
    <!--<v-card-text v-if="items.length > 0">
      <v-expansion-panels id="check">
        <v-expansion-panel
          v-for="(item,i) in items"
          :key="i"
          class="my-1 elevation-0"
        >
          <v-expansion-panel-header class="mx-0 px-1 d-flex justify-space-between align-start align-content-start">
            <div class="d-block">
              <div class='d-flex justify-space-between align-content-start align-start mt-1'>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <p class="mb-2" v-on="on"><strong>{{ item.documentType['document'] | truncate(20) }} </strong></p>
                  </template>
                  <span>{{ item.documentType['document'] }}</span>
                </v-tooltip>
                <p class="ma-0 pa-0" style="font-size: 12px">{{ (new Date(item.dateTime)).toLocaleString() }}</p>
              </div>
              <v-chip x-small>{{ item.dataset }}</v-chip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mx-0 px-0">
            <EmbargoListComponent v-if="item.dataset === 'EMBARGO'" :item="{ result: [{ibama: [{...item.result}], sema: [], icmbio: [], ldi: []}] }" />
            <SlaveryListComponent v-if="item.dataset === 'SLAVERY'" :item="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>-->
    <v-card-text
      v-if="items.length > 0"
      style="display: grid; grid-template-columns: 25% 25% 25% 25%"
    >
      <section v-for="(item, i) in items" :key="i" class="mr-4 mb-4">
        <div class="d-block">
          <div
            class="
              d-flex
              justify-space-between
              align-content-start align-start
              mt-1
            "
          >
            <p class="ma-0 pa-0" style="font-size: 12px">
              {{ new Date(item.dateTime).toLocaleString() }}
            </p>
          </div>
          <v-chip x-small>{{ getDatasetName(item.dataset) }}</v-chip>

          <EmbargoListComponent
            v-if="item.dataset === 'EMBARGO'"
            :item="item"
          />
          <SlaveryListComponent
            v-if="item.dataset === 'SLAVERY'"
            :item="item"
          />
        </div>
      </section>
    </v-card-text>
    <v-card-text v-else>
      {{ $t("general.fields.noDataAvailable") }}
    </v-card-text>
  </v-card>
</template>

<script>
  import EmbargoListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CPF_CNPJ/EmbargoListComponent"
  import SlaveryListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CPF_CNPJ/SlaveryListComponent"
  import { mapGetters } from "vuex"
  import _ from "lodash"

  export default {
    name: "CPFComponent",
    components: { SlaveryListComponent, EmbargoListComponent },
    props: {
      items: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
    },

    computed: {
      ...mapGetters({
        documentType: "storage/documentTypes",
      }),
    },

    methods: {
      getDatasetName(dataset) {
        return _.find(this.documentType.datasets.document, { value: dataset })
          .name
      },
    },
  }
</script>

<style scoped>
  .v-chip.v-size--small,
  .v-chip.v-size--x-small {
    border-radius: 0.3em !important;
  }
  :deep(.v-expansion-panel-content__wrap) {
    padding: 0 0 16px !important;
  }
</style>
