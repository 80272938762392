<template>
  <v-data-footer
    :items-per-page-text="itemPerPageText ? $t('general.table.itemsPerPageText') : ''"
    :page-text="'{0}-{1} ' + $t('general.table.pageText') + ' {2}'"
    v-if="values && values.length > amount"
    :options="paginationOptions"
    :pagination="paginationInfo"
    @update:options="$emit('update:items', $event)"
    :items-per-page-options="itemPerPageText ? paginationOptions.itemsPerPageOptions : []"
    :disable-items-per-page="!valid"
    :disable-pagination="!valid"
  ></v-data-footer>
</template>

<script>
  export default {
    name: "FooterPaginationComponent",

    props: {
      values: {
        type: Array,
        required: true,
      },
      amount: {
        type: Number,
        required: true,
      },
      valid: {
        type: Boolean,
        required: true,
      },
      page: {
        type: Number,
        required: true,
      },
      perPage: {
        type: Number,
        required: true,
      },
      itemsPerPage: {
        type: Array,
        required: true,
      },
      itemPerPageText: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      paginationOptions() {
        return {
          page: this.page,
          itemsPerPage: this.perPage,
          itemsPerPageOptions: this.itemsPerPage,
          showFirstLastPage: true,
        }
      },
      paginationInfo() {
        return {
          page: this.page,
          itemsPerPage: this.perPage,
          itemsLength: this.values.length,
          pageStart: (this.page - 1) * this.perPage + 1,
          pageStop: Math.min(this.page * this.perPage, this.values.length),
        }
      },
    },
  }
</script>

<style scoped>
  .v-data-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
</style>
