import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"px-6 py-2",attrs:{"color":"transparent","elevation":"0","outlined":""}},[_c(VCardTitle,{staticClass:"my-0 py-0"},[_c('strong',[_vm._v("CAR: ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g({staticClass:"mb-2 ml-2 mt-2 black--text",staticStyle:{"font-size":"16px"}},on),[_c('strong',[_vm._v(_vm._s(_vm.items[0].documentType["document"])+" ")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.items[0].documentType["document"]))])])],1),(_vm.items.length > 0)?_c(VCardText,{staticStyle:{"display":"grid","grid-template-columns":"repeat(auto-fill, minmax(200px, 1fr))","gap":"24px"}},_vm._l((_vm.items),function(item,i){return _c('section',{key:i,staticClass:"mb-4"},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex justify-space-between align-content-start align-start mt-1 mr-4"},[_c('p',{staticClass:"ma-0 pa-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(new Date(item.dateTime).toLocaleString())+" ")])]),_c(VChip,{attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.getDatasetName(item.dataset)))]),(item.dataset === 'SOIL_USE_CONSOLIDATED')?_c('SUCListComponent',{attrs:{"items":item.result}}):_vm._e(),(item.dataset === 'COMPLIANCE')?_c('ComplianceListComponent',{attrs:{"items":item.result}}):_vm._e()],1)])}),0):_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("general.fields.noDataAvailable"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }