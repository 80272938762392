import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"color":"transparent","elevation":"0","outlined":""}},[_c(VCardTitle,{staticClass:"subtitle-1 text-uppercase"},[_c('strong',[_vm._v(_vm._s(_vm.$t('storage.integrations.brainag.history')))])])],1),_c(VTimeline,{staticClass:"mt-4",attrs:{"dense":""}},_vm._l((_vm.list),function(item,index){return _c(VTimelineItem,{key:index,attrs:{"icon":"mdi-file-document-outline","fill-dot":true,"color":"white","icon-color":"grey","small":""}},[_c(VListItem,{staticClass:"ma-0 pa-0",attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"black--text font-weight-medium"},[_c('strong',[_vm._v(_vm._s(_vm.getDatasetName(item))+" "),_c(VChip,{attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.list.length - index)+" ")])],1),_c('br'),_c('p',{staticClass:"subtitle-2 ma-0 pa-0"},[_c('strong',[_vm._v(_vm._s(item.type.documentType)+":")]),_c(VTooltip,{attrs:{"bottom":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(item.type.document,20))+" ")])]}}],null,true)},[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.type.document)+" ")])])],1)]),_c(VListItemSubtitle,[_c('p',{staticClass:"subtitle-2 ma-0 pa-0 my-2"},[_vm._v(_vm._s(_vm.$t('storage.integrations.brainag.executedBy'))+": "),_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(item.person))])]),_c('p',{staticClass:"subtitle-2 ma-0 pa-0"},[_vm._v(_vm._s((new Date(item.datetime)).toLocaleString()))]),(item.status === 'COMPLETED')?_c(VChip,{attrs:{"color":"secondary","text-color":"white","x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):_c(VChip,{attrs:{"color":"warning lighten-1","text-color":"black","x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }