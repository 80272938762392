import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{staticClass:"car-list fill-height",staticStyle:{"border":"1px solid #e5e5e5","border-radius":".3em !important"},attrs:{"dense":"","tile":""}},[(_vm.cars.length === 0)?_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium grey--text"},[_vm._v(" "+_vm._s(_vm.$t("general.ui.not_data_found"))+" ")])],1)],1):_c('span',_vm._l((_vm.cars),function(car,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-medium black--text"},[_c('div',{staticClass:"car-chip pa-4",on:{"mouseover":function($event){_vm.hoverIndex = index},"mouseleave":function($event){_vm.hoverIndex = null}}},[_vm._v(" "+_vm._s(car.toUpperCase())+" "),_c(VIcon,{staticClass:"ml-2 copy-icon",attrs:{"small":""},on:{"click":function($event){return _vm.copyToClipboard(car)}}},[_vm._v(" mdi-content-copy ")])],1),_c(VDivider)],1)],1),(_vm.showActions)?_c(VListItemAction,[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"small":"","text":"","icon":""}},[_c(VIcon,{on:{"click":function($event){return _vm.removeCar(index)}}},[_vm._v("mdi-trash-can-outline")])],1)],1)]):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }