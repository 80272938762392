<template>
  <v-card class="px-6 py-2" color="transparent" elevation="0" outlined>
    <v-card-title class="my-0 py-0">
      <strong>CAR: </strong>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p class="mb-2 ml-2 mt-2 black--text" style="font-size: 16px" v-on="on">
            <strong
              >{{ items[0].documentType["document"]  }}
            </strong>
          </p>
        </template>
        <span>{{ items[0].documentType["document"] }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text
      v-if="items.length > 0"
      style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 24px;"
    >
      <section v-for="(item, i) in items" :key="i" class="mb-4">
        <div class="d-block">
          <div class="d-flex justify-space-between align-content-start align-start mt-1 mr-4">
            <p class="ma-0 pa-0" style="font-size: 12px">
              {{ new Date(item.dateTime).toLocaleString() }}
            </p>
          </div>
          <v-chip x-small>{{ getDatasetName(item.dataset) }}</v-chip>

          <SUCListComponent
            :items="item.result"
            v-if="item.dataset === 'SOIL_USE_CONSOLIDATED'"
          />
          <ComplianceListComponent
            :items="item.result"
            v-if="item.dataset === 'COMPLIANCE'"
          />
        </div>
      </section>
    </v-card-text>
    <v-card-text v-else>
      {{ $t("general.fields.noDataAvailable") }}
    </v-card-text>
  </v-card>
</template>

<script>
  import ComplianceListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CAR/ComplianceListComponent.vue"
  import SUCListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CAR/SUCListComponent.vue"
  import _ from "lodash"
  import { mapGetters } from "vuex"

  export default {
    name: "CARComponent",
    components: { SUCListComponent, ComplianceListComponent },
    props: {
      items: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
    },

    computed: {
      ...mapGetters({
        documentType: "storage/documentTypes",
      }),
    },

    methods: {
      getDatasetName(dataset) {
        return _.find(this.documentType.datasets.car, { value: dataset }).name
      },
    },
  }
</script>

<style scoped>
  .v-chip.v-size--small,
  .v-chip.v-size--x-small {
    border-radius: 0.3em !important;
  }
  :deep(.v-expansion-panel-content__wrap) {
    padding: 0 0 16px !important;
  }
</style>
