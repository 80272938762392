<template>
  <div class="d-block mt-2">
    <div v-for="(key, index) in keys" :key='index' class="d-flex justify-start align-center align-content-center">
      <v-icon style="font-size: 16px"
              :style="!item.result[0][key].length > 0 ? 'color: green' : 'color: red'"
      >
        {{ !item.result[0][key].length > 0 ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
      </v-icon>
      <p class="ma-0 pa-0 mx-2">{{ title(key) }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmbargoListComponent',
    props: {
      item: {
        type: Object,
        required: true,
        default: () => {
          return []
        }
      }
    },

    data:() => ({
      keys: ["ibama", "sema", "icmbio", "ldi"]
    }),

    methods: {
      title(key) {
        switch (key) {
          case "ibama":
            return "IBAMA"
          case "sema":
            return "SEMA"
          case "icmbio":
            return "ICMBIO"
          case "ldi":
            return "LDI-PA"
        }
      }
    }
  }
</script>

<style scoped>

</style>